import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  APPOINTMENT_DURATION,
  BARBERSHOP_CLOSE_TIME,
  BARBERSHOP_OPEN_TIME,
} from '../../constants'
import { useInsertBarber, useUpdateBarber } from '../../hooks'
import { Barber } from '../../types'
import {
  addMinutes,
  format,
  formatPhoneToInt,
  formatTo12Hr,
  formatTo24Hr,
  isEmailValid,
  parseLocalTime,
  setDay,
  setHours,
  setMinutes,
  stopEvent,
} from '../../util'
import Dialog, { CommonDialogProps } from '../Dialog/Dialog'
import Input from '../Input/Input'
import PhoneInput from '../PhoneInput/PhoneInput'
import Select from '../Select/Select'

type Props = {
  barber?: Barber
  onSuccess?: () => void
} & Omit<CommonDialogProps, 'title'>

const defaultShift = { start: '', end: '' }
const defaultAvailability = Array(7).fill(defaultShift)

const BarberDialog: FC<Props> = ({ barber, onSuccess, ...props }) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isinvalidEmail, setIsInvalidEmail] = useState(false)
  const [isInvalidPhone, setIsInvalidPhone] = useState(false)
  const [availability, setAvailability] =
    useState<Barber['availability']>(defaultAvailability)
  const { insertBarber } = useInsertBarber()
  const { updateBarber } = useUpdateBarber()

  const isComplete = useMemo(
    () => [name, phone, email].every(Boolean),
    [phone, name, email]
  )

  const clearState = useCallback(() => {
    setName('')
    setEmail('')
    setPhone('')
    setAvailability(defaultAvailability)
  }, [])

  const onSave = useCallback(async () => {
    setIsSaving(true)

    const invalidEmail = !isEmailValid(email)
    const invalidPhone = phone.length !== 12

    setIsInvalidEmail(invalidEmail)
    setIsInvalidPhone(invalidPhone)

    if (invalidEmail || invalidPhone) {
      setIsSaving(false)
      return
    }

    const phoneNumber = formatPhoneToInt(phone)

    const input = {
      name,
      email,
      phone_number: phoneNumber,
      availability: availability.map((shift) => {
        if (!shift?.end || !shift.start || shift.start >= shift.end) {
          return null
        }

        return shift
      }),
    }

    let errors
    if (barber) {
      const { errors: reqErrors } = await updateBarber(barber._id, input)

      errors = reqErrors
    } else {
      const { errors: reqErrors } = await insertBarber(input)

      errors = reqErrors
    }

    setIsSaving(false)

    if (!errors) {
      clearState()
      onSuccess?.()
    }
  }, [
    email,
    phone,
    name,
    availability,
    barber,
    updateBarber,
    insertBarber,
    clearState,
    onSuccess,
  ])

  const timeOptions = useMemo(() => {
    const { hours, minutes } = parseLocalTime(BARBERSHOP_OPEN_TIME)
    const startDate = setMinutes(setHours(new Date(), hours), minutes)

    let start = String(BARBERSHOP_OPEN_TIME)
    let end = String(BARBERSHOP_CLOSE_TIME)

    const options = [{ label: formatTo12Hr(startDate), value: start }]
    while (start !== end) {
      const { hours, minutes } = parseLocalTime(start)
      const startDate = setMinutes(setHours(new Date(), hours), minutes)

      const date = addMinutes(startDate, APPOINTMENT_DURATION)

      const label = formatTo12Hr(date)
      const value = formatTo24Hr(date)

      options.push({ value, label })

      start = value
    }

    return options
  }, [])

  const availabilityInputs = useMemo(() => {
    return availability.map((shift, i) => {
      if (!shift) return shift

      const date = setDay(new Date(), i)

      return (
        <div className="row" key={`availability-${i}`}>
          <div className="col-3 text-center">{format(date, 'EEEE')}:</div>
          <div className="col">
            <Select
              label={'Start'}
              onChange={(e) => {
                setAvailability(
                  availability.map((a, j) =>
                    i === j && a ? { ...a, start: e.target.value } : a
                  )
                )
              }}
              value={shift.start}
              disabled={isSaving}
              options={timeOptions}
            />
          </div>
          <div className="col">
            <Select
              label={'End'}
              onChange={(e) => {
                setAvailability(
                  availability.map((a, j) =>
                    i === j && a ? { ...a, end: e.target.value } : a
                  )
                )
              }}
              value={shift.end}
              disabled={isSaving}
              options={timeOptions.filter((op) => op.value > shift.start)}
            />
          </div>
        </div>
      )
    })
  }, [availability, isSaving, timeOptions])

  const body = useMemo(() => {
    return (
      <>
        <Input
          label={'Name'}
          onChange={(e) => {
            setName(e.target.value)
          }}
          value={name}
          disabled={isSaving || !!barber}
        />
        <Input
          label={'Email address'}
          onChange={(e) => {
            setEmail(e.target.value)
            setIsInvalidEmail(false)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Space') {
              stopEvent(e)
            }
          }}
          disabled={isSaving}
          value={email}
          isInvalid={isinvalidEmail}
          invalidFeedback={'Invalid email address.'}
        />
        <PhoneInput
          value={phone}
          disabled={isSaving}
          onChange={(val) => {
            if (val !== phone) {
              setPhone(val)
              setIsInvalidPhone(false)
            }
          }}
          isInvalid={isInvalidPhone}
        />
        {availabilityInputs}
      </>
    )
  }, [
    availabilityInputs,
    barber,
    email,
    isInvalidPhone,
    isSaving,
    isinvalidEmail,
    name,
    phone,
  ])

  const footer = useMemo(() => {
    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={onSave}
        disabled={!isComplete || isSaving}
      >
        Save
      </button>
    )
  }, [isComplete, isSaving, onSave])

  useEffect(() => {
    if (barber) {
      setName(barber.name)
      setEmail(barber.email)
      setPhone(barber.phone_number)
      setAvailability(barber.availability.map((a) => (a ? a : defaultShift)))
    } else {
      clearState()
    }
  }, [barber, clearState])

  return (
    <Dialog
      {...props}
      title={barber ? 'Update Barber' : 'Add Barber'}
      body={body}
      footer={footer}
    />
  )
}

export default BarberDialog
