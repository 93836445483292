import { FC } from 'react'
import { galleryImages } from '../../assets/img'
import Carousel from '../Carousel/Carousel'

type Props = {
  title: string
}

const GallerySection: FC<Props> = ({ title }) => {
  return (
    <section id="Gallery">
      <div className="container px-4 px-lg-5 pb-5">
        <h2 className="text-center mt-0">{title}</h2>
        <hr className="divider" />
        <div className="row justify-content-center">
          <div className="col">
            <Carousel images={galleryImages} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GallerySection
