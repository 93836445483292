// import { useGoogleLogin } from '@react-oauth/google'
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
// import { useToaster } from '../../hooks'
import {
  //CancelToken, Mutation,
  User,
} from '../../types'
// import {
//   executeGraphQL,
//   getCancelTokenSource,
//   isCancelError,
// } from '../../util'

type Props = { children?: ReactNode }

type ProviderContext = {
  logout: () => void
  // login: ReturnType<typeof useGoogleLogin>
  jwt: string
  user?: User
}

export const UserProviderContext = createContext<ProviderContext | null>(null)

const UserProvider: FC<Props> = ({ children }) => {
  //const loginErrorMessage = 'Unable to log in. Please try again later.'

  // const { popToast } = useToaster()
  // const login = useGoogleLogin({
  //   onSuccess: (res) => {
  //     setAccessToken(res.access_token)
  //   },
  //   onError: () => {
  //     popToast({ message: loginErrorMessage, type: 'error' })
  //   },
  //   onNonOAuthError: () => {
  //     popToast({ message: loginErrorMessage, type: 'error' })
  //   },
  // })

  const logout = useCallback(() => {
    setAccessToken('')
    setJwt('')
    setUser(undefined)
  }, [])

  const [accessToken, setAccessToken] = useState('')
  const [jwt, setJwt] = useState('')
  const [user, setUser] = useState<User>()

  // const loginUser = useCallback(
  //   async (token: string, cancelToken: CancelToken) => {
  //     try {
  //       const { data, errors } = await executeGraphQL<Mutation>({
  //         gql: loginGQL,
  //         vars: { token },
  //         cancelToken,
  //       })

  //       const { jwt, user } = data?.user.login ?? {}

  //       if (errors) {
  //         popToast({ message: loginErrorMessage, type: 'error' })
  //       }

  //       if (jwt) {
  //         setJwt(jwt)
  //       }

  //       if (user) {
  //         setUser(user)
  //       }
  //     } catch (err) {
  //       if (!isCancelError(err)) {
  //         popToast({ message: loginErrorMessage, type: 'error' })
  //       }
  //     }
  //   },
  //   [popToast]
  // )

  useEffect(() => {
    // const source = getCancelTokenSource()
    // if (accessToken && !jwt) {
    //   loginUser(accessToken, source.token)
    // }
    // return () => {
    //   source.cancel()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, jwt])

  return (
    <UserProviderContext.Provider
      value={{
        //login,
        jwt,
        user,
        logout,
      }}
    >
      {children}
    </UserProviderContext.Provider>
  )
}

// const loginGQL = `
//   mutation login($token: String!) {
//     user {
//       login(token: $token) {
//         jwt
//         user {
//           _id
//           is_admin
//           name
//           email
//           created_at
//           updated_at
//           last_login
//         }
//       }
//     }
//   }
// `

export default UserProvider
