import { FC, MouseEventHandler } from 'react'

type Props = {
  title: string
  subTitle: string
  buttonText: string
  buttonHandler?: MouseEventHandler<HTMLButtonElement>
}

const AboutSection: FC<Props> = ({
  title,
  subTitle,
  buttonText,
  buttonHandler,
}) => {
  return (
    <section className="page-section bg-primary" id="About">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5 justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="text-white mt-0">{title}</h2>
            <hr className="divider divider-light" />
            <p className="text-white-75 mb-4">{subTitle}</p>
            <button className="btn btn-light btn-xl" onClick={buttonHandler}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection
