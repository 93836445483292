import { FC, MouseEventHandler } from 'react'

type Props = {
  title: string
  subTitle: string
  buttonText: string
  buttonHandler?: MouseEventHandler<HTMLButtonElement>
}

const Masthead: FC<Props> = ({
  title,
  subTitle,
  buttonText,
  buttonHandler,
}) => {
  return (
    <header id="Masthead">
      <div className="container px-4 px-lg-5 h-100">
        <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
          <div className="col-lg-8 align-self-end">
            <h1 className="text-white font-weight-bold">{title}</h1>
            <hr className="divider" />
          </div>
          <div className="col-lg-8 align-self-baseline">
            <p className="text-white-75 mb-5">{subTitle}</p>
            <button className="btn btn-primary btn-xl" onClick={buttonHandler}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Masthead
