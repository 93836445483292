// import { GoogleOAuthProvider } from '@react-oauth/google'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
// import { getConfig } from './util'
import App from './App'
import { DataProvider, ToastProvider, UserProvider } from './providers'
import { APP_ELEMENT_ID } from './constants'

import './scss/styles.scss'

const renderApp = () => {
  const element = document.getElementById(APP_ELEMENT_ID)

  if (!element) {
    throw Error('Unable to find app html element.')
  }

  const root = ReactDOM.createRoot(element)

  // const { googleClientId } = getConfig()

  root.render(
    // <GoogleOAuthProvider clientId={googleClientId}>
    <BrowserRouter>
      <ToastProvider>
        <UserProvider>
          <DataProvider>
            <App />
          </DataProvider>
        </UserProvider>
      </ToastProvider>
    </BrowserRouter>
    //</GoogleOAuthProvider>
  )
}

renderApp()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
