// import { useUser } from '../../hooks'
import { format, now } from '../../util'
import fbicon from '../../assets/icons/fb-icon.png'

const Footer = () => {
  // const { login, user, logout } = useUser()

  return (
    <footer id="Contact" className="bg-dark text-white py-5">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5">
          <div className="col-lg-12 col-md-12 text-center">
            <div className="mt-5">
              <p className="text-muted mb-1">Kelley's Barbershop</p>
              <p className="text-muted mb-1">4 NY-31, Lyons, NY 14489</p>
              <p className="text-muted mb-1">(315) 759-7979</p>
            </div>
            <a
              href="https://www.facebook.com/KelleysBarbershop14489/"
              style={{ cursor: 'pointer' }}
            >
              <img src={fbicon} alt="fb-icon" />
            </a>
            <p className="text-muted mb-1">
              Copyright &copy; {format(now(), 'yyyy')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
