import { FC } from 'react'
// import { useNavigate } from 'react-router-dom'
import {
  Masthead,
  AboutSection,
  ServicesSection,
  GallerySection,
} from '../../components'
// import { ROUTES } from '../../constants'
import { useData } from '../../hooks'
import { scrollToTop } from '../../util'

type Props = {}

const Main: FC<Props> = () => {
  const { services } = useData()

  // const navigate = useNavigate()

  return (
    <>
      <Masthead
        title="Experience the Best in Small Town Barbering"
        subTitle="Get a fresh new look! Visit our small town barbershop for expert
                  haircuts, shaves, and grooming services. Book your appointment
                  today!"
        buttonText="Book now"
        buttonHandler={() => {
          // navigate(ROUTES.BOOKING)
          window.open('https://kelleysbarbershop.schedulista.com/')
          scrollToTop()
        }}
      />
      <AboutSection
        title="Community Babershop since 2011"
        subTitle="Our small town barbershop has been a staple of the community for
                  years, providing expert grooming services in a friendly and
                  welcoming atmosphere. From haircuts, trims and shaves, our
                  talented barbers use only the best techniques to give you a look
                  you'll love. Whether you're a local or just passing through, come
                  visit us in Lyons and experience the best in small town barbering."
        buttonText="Get Directions"
        buttonHandler={() => {
          window.open(
            'https://www.google.com/maps/dir/?api=1&destination=Kelleys+Barbershop,+4+NY-31,+Lyons,+NY+14489',
            '_blank'
          )
        }}
      />
      <ServicesSection
        title="Our Services"
        services={services}
        subTitle="All services can be bundled for a cheaper total price."
      />
      <GallerySection title="Gallery" />
    </>
  )
}

export default Main
