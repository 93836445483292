import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

type Props = { children?: ReactNode }

type ProviderContext = {
  popToast: (message: ToastState) => void
}

type ToastState = { message: string; type: 'success' | 'error'; title?: string }

export const ToastProviderContext = createContext<ProviderContext | null>(null)

const ToastProvider: FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastState[]>([])

  const popToast = useCallback(
    (toast: ToastState) => {
      setToasts([toast, ...toasts])
    },
    [toasts]
  )

  const getColor = useCallback((toast: ToastState) => {
    if (toast.type === 'error') {
      return 'danger'
    }

    return 'success'
  }, [])

  const getTitle = useCallback((toast: ToastState) => {
    if (toast.title) {
      return toast.title
    }

    if (toast.type === 'error') {
      return 'Error!'
    }

    return 'Success!'
  }, [])

  const toastElements = useMemo(() => {
    const elements = toasts.map((toast, i) => (
      <div
        className={`toast fade show align-items-center text-bg-${getColor(
          toast
        )} border-0`}
        key={`toast-${i}`}
      >
        <div className="d-flex">
          <div className="toast-body w-100">
            <div className="row pb-1">
              <div className="col">
                <strong>{getTitle(toast)}</strong>
              </div>
              <div className="col text-end">
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={() => {
                    setToasts(toasts.filter((_, j) => i !== j))
                  }}
                ></button>
              </div>
            </div>
            <span>{toast.message}</span>
          </div>
        </div>
      </div>
    ))

    return elements
  }, [getColor, getTitle, toasts])

  return (
    <ToastProviderContext.Provider value={{ popToast }}>
      <div className="toast-container top-0 end-0 position-fixed p-3">
        {toastElements}
      </div>
      {children}
    </ToastProviderContext.Provider>
  )
}

export default ToastProvider
