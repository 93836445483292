import { useContext } from 'react'
import { DataProviderContext } from '../../providers'

const useData = () => {
  const context = useContext(DataProviderContext)

  if (!context) {
    throw Error('DataProviderContext is not defined. ')
  }

  return context
}

export default useData
