import { KeyboardEvent } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { APP_ELEMENT_ID, PHONE_DELIMITER, PHONE_PREFIX } from '../constants'
import { GraphQLRequest, GraphQLResponse } from '../types'
import { getConfig } from './config'
import { format } from 'date-fns'

export * from './config'
export * from 'date-fns'
export { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export const now = () => new Date()

export const parseLocalTime = (time: string) => {
  const [hh, mm] = time.split(':')

  const hours = parseInt(hh)
  const minutes = parseInt(mm)

  return { hours, minutes }
}

export const executeGraphQL = async <T>({
  gql: query,
  vars: variables = {},
  token,
  cancelToken,
}: GraphQLRequest) => {
  let headers: AxiosRequestConfig['headers'] = {}

  if (token) {
    headers = { authorization: getBearer(token) }
  }

  const { graphqlApiUrl } = getConfig()

  const { data } = await axios.post<GraphQLResponse<T>>(
    graphqlApiUrl,
    {
      query,
      variables,
    },
    { headers, cancelToken }
  )

  return data
}

export const getBearer = (token: string) => {
  const BEARER_STRING = 'Bearer' as const

  const str = `${BEARER_STRING} ${token}`

  return str
}

export const getCancelTokenSource = () => {
  const source = axios.CancelToken.source()

  return source
}

export const isCancelError = (err: unknown) => axios.isCancel(err)

export const scrollIntoView = (id: string) => {
  const element = document.getElementById(id)

  if (element) {
    element.scrollIntoView()
  }
}

export const scrollToTop = () => {
  scrollIntoView(APP_ELEMENT_ID)
}

export const stopEvent = (e: KeyboardEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

export const isEmailValid = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const isValid = regex.test(email)

  return isValid
}

export const formatPhoneToInt = (phone: string) => {
  const phoneWithNoDelim = phone.replaceAll(PHONE_DELIMITER, '')

  const formattedPhone = PHONE_PREFIX + phoneWithNoDelim

  return formattedPhone
}

export const formatPhoneToLocal = (phone: string) => {
  const formattedPhone = phone.startsWith(PHONE_PREFIX)
    ? phone.substring(PHONE_PREFIX.length)
    : phone

  const areaCode = formattedPhone.substring(0, 3)
  const telePrefix = formattedPhone.substring(3, 6)
  const lineNumber = formattedPhone.substring(6, 10)

  const retPhone = [areaCode, telePrefix, lineNumber].join('-')

  return retPhone
}

export const formatTo12Hr = (date: Date) => {
  return format(date, 'hh:mm aaa')
}

export const formatTo24Hr = (date: Date) => {
  return format(date, 'HH:mm')
}

export const formatToFullDate = (date: Date) => {
  return format(date, 'MM-dd-yyyy hh:mm aaa')
}

export const formatToMDY = (date: Date) => {
  return format(date, 'MM-dd-yyyy')
}

export const formatToYMD = (date: Date) => {
  return format(date, 'yyyy-MM-dd')
}

export const parsePhone = (phone: string) => {
  const delimiter = PHONE_DELIMITER

  const [areaCode, telePrefix, lineNumber] = phone.split(delimiter)

  return {
    areaCode,
    telePrefix,
    lineNumber,
  }
}
