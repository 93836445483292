import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

type Props = { images: string[]; tickerMs?: number }

const getIndexes = (curr: number, length: number) => {
  const next = curr + 1
  const prev = curr - 1

  return {
    prev: prev < 0 ? length - 1 : prev,
    next: next >= length ? 0 : next,
  }
}

const Carousel: FC<Props> = ({ images, tickerMs = 5000 }) => {
  const [selected, setSelected] = useState(0)
  const [disable, setDisabled] = useState<NodeJS.Timeout>()

  const imageTicker = useRef<NodeJS.Timer>()

  const startTicker = useCallback(() => {
    const handler = () => {
      setSelected((s) => {
        const { next } = getIndexes(s, images.length)

        return next
      })
    }

    imageTicker.current = setInterval(handler, tickerMs)
  }, [images.length, tickerMs])

  const stopTicker = useCallback(() => {
    clearInterval(imageTicker.current)
  }, [])

  const clickHandler = useCallback(
    (i: number) => {
      setDisabled(setTimeout(() => setDisabled(undefined), 1000))
      stopTicker()
      setSelected(i)
      startTicker()
    },
    [startTicker, stopTicker]
  )

  const isSelectedClass = useCallback(
    (i: number, selectedClass = 'active', defaultClass = '') =>
      selected === i ? selectedClass : defaultClass,
    [selected]
  )

  const displayImagesElements = useMemo(() => {
    const { next, prev } = getIndexes(selected, images.length)

    return images.map((image, i) => {
      const classes = [
        'carousel-item',
        isSelectedClass(i),
        next === i ? 'carousel-item-next' : '',
        prev === i ? 'carousel-item-prev' : '',
      ]

      return (
        <div className={classes.join(' ')} key={`image-carousel-${i}`}>
          <img className="img-fluid" src={image} alt="" />
        </div>
      )
    })
  }, [images, isSelectedClass, selected])

  const imageButtonElements = useMemo(() => {
    return images.map((_, i) => {
      return (
        <button
          type="button"
          data-bs-target="#carouselcomponent"
          className={isSelectedClass(i)}
          key={`button-carousel-${i}`}
          onClick={() => clickHandler(i)}
          disabled={!!disable}
        ></button>
      )
    })
  }, [disable, clickHandler, images, isSelectedClass])

  useEffect(() => {
    startTicker()

    return () => {
      clearInterval(imageTicker.current)
      clearTimeout(disable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTicker])

  return (
    <div id="carouselcomponent" className="carousel">
      <div className="carousel-indicators">{imageButtonElements}</div>
      <div className="carousel-inner">{displayImagesElements}</div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselcomponent"
        disabled={!!disable}
        onClick={() => {
          const { prev } = getIndexes(selected, images.length)

          clickHandler(prev)
        }}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselcomponent"
        disabled={!!disable}
        onClick={() => {
          const { next } = getIndexes(selected, images.length)

          clickHandler(next)
        }}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Carousel
