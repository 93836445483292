import { Footer, Navbar } from './components'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  //Navigate,
} from 'react-router-dom'
import {
  //Admin, Booking,
  Home,
} from './containers'
import { useState, useEffect, useMemo } from 'react'
import { scrollIntoView, scrollToTop } from './util'
import { ROUTES } from './constants'
// import { useUser } from './hooks'

const App = () => {
  const [scrolled, setScrolled] = useState(false)

  //const { user } = useUser()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const onScroll = () => {
      if (location.pathname === ROUTES.HOME && window.scrollY === 0) {
        setScrolled(false)
      } else {
        setScrolled(true)
      }
    }

    onScroll()

    window.addEventListener('scroll', onScroll, { passive: true })

    return () => window.removeEventListener('scroll', onScroll)
  }, [location.pathname])

  const options = useMemo(
    () =>
      ['About', 'Services', 'Gallery', 'Contact'].map((title) => ({
        title,
        onClick: () => {
          navigate(ROUTES.HOME)
          setTimeout(() => scrollIntoView(title))
        },
      })),
    [navigate]
  )

  return (
    <>
      <Navbar
        title={"Kelley's Barbershop"}
        navOptions={options}
        showScrolled={scrolled}
        titleOnClick={() => {
          navigate(ROUTES.HOME)
          scrollToTop()
          setScrolled(false)
        }}
      />
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        {/* <Route path={ROUTES.BOOKING} element={<Booking />} />
        <Route
          path={ROUTES.ADMIN}
          element={
            user?.is_admin ? <Admin /> : <Navigate to={ROUTES.HOME} replace />
          }
        /> */}
      </Routes>
      <Footer />
    </>
  )
}

export default App
