import { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { useUser, useWindowDimensions } from '../../hooks'
import icon from '../../assets/icons/favicon.png'

type NavOption = {
  title: string
  onClick?: () => void
}

type Props = {
  title: string
  titleOnClick?: () => void
  navOptions: NavOption[]
  showScrolled?: boolean
}

const Navbar: FC<Props> = ({
  title,
  navOptions,
  showScrolled = false,
  titleOnClick,
}) => {
  const { user } = useUser()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const [show, setShow] = useState(false)

  const navElements = useMemo(() => {
    const options = [...navOptions]

    if (user?.is_admin) {
      const adminOption = {
        title: 'Admin',
        onClick: () => {
          navigate(ROUTES.ADMIN)
        },
      }

      options.push(adminOption)
    }

    const elements = options.map(({ title, onClick }) => {
      return (
        <li
          className="nav-item"
          key={`nav-item-${title}`}
          onClick={() => {
            setShow(false)
            onClick?.()
          }}
        >
          <span className="nav-link">{title}</span>
        </li>
      )
    })

    return elements
  }, [navOptions, navigate, user])

  const iconElement = useMemo(() => {
    const element = (
      <img
        src={icon}
        alt="icon"
        className="mr-5"
        style={{ maxWidth: 40, maxHeight: 40, marginRight: '1rem' }}
      />
    )

    if (showScrolled || width <= 990) {
      return element
    }
  }, [showScrolled, width])

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top py-3 ${
        showScrolled ? 'navbar-scrolled' : ''
      }`}
      id="Navbar"
    >
      <div className="container px-4 px-lg-5">
        {iconElement}
        <span className="navbar-brand" onClick={titleOnClick}>
          {title}
        </span>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          onClick={() => {
            setShow(!show)
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${show ? 'show' : ''}`}>
          <ul className="navbar-nav ms-auto my-2 my-lg-0">{navElements}</ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
