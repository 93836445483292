import { useContext } from 'react'
import { UserProviderContext } from '../../providers'

const useUser = () => {
  const context = useContext(UserProviderContext)

  if (!context) {
    throw Error('UserProviderContext is not defined. ')
  }

  return context
}

export default useUser
