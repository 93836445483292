import { FC, useMemo } from 'react'
import { Service } from '../../types'

type Props = {
  services: Service[]
  title: string
  subTitle: string
}

const ServicesSection: FC<Props> = ({ services, title, subTitle }) => {
  const serviceElements = useMemo(() => {
    const getElementSize = () => {
      if (services.length % 4 === 0) return '3'
      if (services.length % 3 === 0) return '4'

      return '6'
    }

    const size = getElementSize()

    const elements = services
      .sort((a, b) => b.price - a.price)
      .map((service) => (
        <div className={`col-lg-${size} text-center`} key={service._id}>
          <div className="mt-5">
            <h3 className="h4">{service.name}</h3>
            <p className="text-primary">${service.price}</p>
            <p className="text-muted">{service.description}</p>
          </div>
        </div>
      ))

    return elements
  }, [services])

  return (
    <section className="page-section" id="Services">
      <div className="container px-4 px-lg-5">
        <h2 className="text-center mt-0">{title}</h2>
        <hr className="divider" />
        <p className="text-center text-muted mb-4">{subTitle}</p>
        <div className="row gx-4 gx-lg-5">{serviceElements}</div>
      </div>
    </section>
  )
}

export default ServicesSection
