export const ROUTES = {
  HOME: '/',
  BOOKING: '/booking',
  ADMIN: '/admin',
} as const

export const APP_ELEMENT_ID = 'root' as const

export const APPOINTMENT_DURATION = 30 as const
export const APPOINTMENT_MAX_DAYS = 90 as const
export const APPOINTMENT_MIN_DAYS = 0 as const
export const BARBERSHOP_TIMEZONE = 'America/New_York' as const

export const PHONE_DELIMITER = '-' as const
export const PHONE_PREFIX = '+1' as const

export const BARBERSHOP_OPEN_TIME = '05:00' as const
export const BARBERSHOP_CLOSE_TIME = '22:00' as const
