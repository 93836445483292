import config from '../config.json'

let _config: typeof config

export const getConfig = () => {
  if (!_config) {
    initializeConfig()
  }

  return _config
}

export const initializeConfig = () => {
  if (_config) {
    return
  }

  _config = { ...config }
}
