import { useContext } from 'react'
import { ToastProviderContext } from '../../providers'

const useToaster = () => {
  const context = useContext(ToastProviderContext)

  if (!context) {
    throw Error('ToastProviderContext is not defined. ')
  }

  return context
}

export default useToaster
