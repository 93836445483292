import haircut1 from './gallery/haircut1.jpg'
import haircut2 from './gallery/haircut2.jpg'
import haircut3 from './gallery/haircut3.jpg'
import haircut4 from './gallery/haircut4.jpg'
import haircut5 from './gallery/haircut5.jpg'
import haircut6 from './gallery/haircut6.jpg'
import haircut7 from './gallery/haircut7.jpg'
import haircut8 from './gallery/haircut8.jpg'
import haircut9 from './gallery/haircut9.jpg'
import haircut10 from './gallery/haircut10.jpg'
import haircut11 from './gallery/haircut11.jpg'
import haircut12 from './gallery/haircut12.jpg'
import haircut13 from './gallery/haircut13.jpg'
import haircut14 from './gallery/haircut14.jpg'
import haircut15 from './gallery/haircut15.jpg'
import haircut16 from './gallery/haircut16.jpg'
import haircut17 from './gallery/haircut17.jpg'
import haircut18 from './gallery/haircut18.jpg'
import haircut19 from './gallery/haircut19.jpg'
import haircut20 from './gallery/haircut20.jpg'
import haircut21 from './gallery/haircut21.jpg'

export const galleryImages = [
  haircut1,
  haircut2,
  haircut3,
  haircut4,
  haircut5,
  haircut6,
  haircut7,
  haircut8,
  haircut9,
  haircut10,
  haircut11,
  haircut12,
  haircut13,
  haircut14,
  haircut15,
  haircut16,
  haircut17,
  haircut18,
  haircut19,
  haircut20,
  haircut21,
]
